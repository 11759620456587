.login {
  width: 80%;
  margin: auto;
}

.title {
  text-align: center;
  padding: 24px;
}

.error {
  margin-bottom: 16px !important;
}
