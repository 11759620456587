.itemCard {
  margin-bottom: 16px !important;
  margin-right: 16px !important;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.08);
  border: 1px solid #004fad !important;
}

/* ItemCard style for starred items. */
.itemCardStarred {
  margin-bottom: 16px !important;
  margin-right: 16px !important;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.08);
  border: 5px solid #ff0000 !important;
}

/* ItemCard style for starred items. */
.itemCardTogo {
  margin-bottom: 16px !important;
  margin-right: 16px !important;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.08);
  border: 5px solid #ffa500 !important;
}

.itemCardBody {
  display: flex;
  align-items: center;
  font-size: 18px;
  padding: 16px;
}

.itemInfo {
  flex: 1;
  padding-right: 16px;
}

.itemCount {
  font-size: 42px;
}

.itemDetails {
  display: flex;
}

.countButtons {
  flex: 1;
}

.countButton {
  height: 72px !important;
  min-width: 72px;
  line-height: 70px !important;
  font-size: 36px !important;
  margin-right: 8px !important;
  margin-bottom: 8px !important;
  text-align: center;
}

.doneButton {
  font-size: 18px !important;
  width: 100%;
  height: 52px !important;
  margin-top: 16px !important;
}

.doneButtonColor {
  background-color: rgb(53, 134, 0) !important;
  border-color: rgb(53, 134, 0) !important;
  color: #fff !important;
}
