/* Hack to make bigger checkboxes */
.pendingOrderCol .ant-checkbox {
  top: 50%;
  transform: translateY(-50%);
  margin: 0px 8px;
}

.pendingOrderCol .ant-checkbox-inner {
  width: 64px;
  height: 64px;
}

.pendingOrderCol .ant-checkbox-checked .ant-checkbox-inner::after {
  transform: rotate(45deg) scale(4) translate(20%, -50%);
}

/* Hack to make drawer wider so that it's more easily visible in small tablet screen */
@media (max-width: 1199px) {
  .ant-drawer-open {
    width: 100vw !important;
  }
}

@media (min-width: 1200px) {
  .ant-drawer-open {
    width: 50vw !important;
  }
}

.ant-drawer-close {
  left: 0 !important; /* show close button on left side of drawer */
}

.ant-drawer-title {
  margin-left: 16px !important; /* leave space for close button */
  line-height: inherit !important;
}

/* Hack to make date-picker bigger */
.ant-picker-dropdown {
  font-size: 24px !important;
}

.ant-picker-dropdown .ant-btn {
  font-size: 24px !important;
  height: 40px !important;
}
