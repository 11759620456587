.item {
  display: flex;
  font-size: 18px;
}

.itemQty {
  margin-right: 8px;
}

.itemText {
  flex: 1;
}

.star {
  font-size: 20px !important;
  margin: 0px 8px;
}

.itemNotes {
}
