.startedOrders {
  background-color: rgba(0, 79, 172, 0.5);
  padding: 24px;
}

.heading {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
}

.orderDetails {
  font-size: 18px;
}

.warning {
  margin-bottom: 8px !important;
}

.notesAndPrint {
  display: flex;
  align-items: center;
}

.orderNotes {
  flex: 1;
  word-break: break-all;
  padding-right: 16px;
}

.item {
  border-bottom: 1px solid #f0f0f0;
  padding: 8px 0px !important;
}

.lastItem {
  border-bottom: none;
}

.noActionItem {
  color: rgba(0, 0, 0, 0.25);
}

.startedOrderItem {
  display: flex !important;
  align-items: center;
  margin-left: 0px !important;
}

.itemWithCheckbox {
  margin-left: 8px;
  font-size: 18px;
}

.startedOrderItem span:nth-child(2) {
  flex: 1;
}

.itemStatus0 {
  color: #999b08;
}

.itemStatus1 {
  color: #004fac;
}

.itemStatus2 {
  color: #358600;
}

.star {
  font-size: 60px !important;
  margin: 8px !important;
  align-self: center;
}

/*
 * Hack to position modal over middle column.
 */
.modalWrap {
  left: 20vw !important;
}

.modalFooter {
  display: flex;
  justify-content: space-between;
}

.modalFooterRight {
  display: flex;
}

.undoButton {
  background-color: #FAFBB1 !important;
  border-color: #FAFBB1 !important;
  color: rgba(0, 0, 0, 0.85) !important;
  width: 96px;
  height: inherit !important;
}

.printButton {
  width: 96px;
  height: 48px !important;
}

.printNonLabelItemButton {
  width: 160px;
  height: 48px !important;
  background-color: rgb(60, 60, 60) !important;
}

.doneButton {
  background-color: rgb(53, 134, 0) !important;
  border-color: rgb(53, 134, 0) !important;
  color: #fff !important;
  width: 96px;
  margin-left: 16px;
}

.startedOrderSubCol {
  max-height: 80vh;
  overflow: auto;
}

.itemCard {
  margin-bottom: 8px !important;
}

.selectedItemCard {
  background-color: #004fad !important;
}

.selectedMessage {
  background-color: white;
  font-size: 16px;
  padding: 16px;
}

.selectedItem {
  margin: 8px 0px;
  font-weight: bold;
}
