.websocketDisconnect {
  margin-bottom: 32px !important;
}

.priority {
  display: flex;
  border-bottom: 2px solid #f0f0f0;
  padding: 16px 0px;
  align-items: center;
}

.lastPriority {
  border-bottom: none;
}

.priorityLabel {
  margin: 0px 8px;
  flex-basis: 72px;
}

.star {
  font-size: 60px !important;
}

.toGoTag {
  height: 32px;
  width: 64px;
  text-align: center;
  padding: 4px 0px;
}

.items {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}
