.header {
  box-shadow: 0 2px 8px #f0f1f2;
  padding: 12px;
  background-color: #004FAC;
  font-size: 20px;
  color: white;
}

.back {
  margin-right: 8px;
}

.ticketRails {
  font-weight: bold;
  color: white !important;
}

.title {
}

.titleSpacer {
  margin: 0px 8px;
}

.headerButton {
  display: inline-block;
  color: white !important;
}

.headerButtonContainer {
  float: right;
  margin-right: 8px;
}

.content {
  padding: 24px 0px;
  width: 96%;
  margin: auto;
}
