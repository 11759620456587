.orderDetails {
  font-size: 18px;
}

.warning {
  margin-bottom: 8px !important;
}

.remindButton {
  margin-left: 8px !important;
}

.datePicker {
}

.orderNotes {
  margin-top: 8px;
}

.item {
  border-bottom: 1px solid #f0f0f0;
  padding: 8px 0px !important;
}

.lastItem {
  border-bottom: none;
}

.noActionItem {
  color: rgba(0, 0, 0, 0.25);
}
