.wideContainer {
  width: 150%;
}

.order {
}

.orderFlashing {
  animation: blinker 3s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.orderType {
  margin-left: 8px !important;
}

.orderError {
  margin-left: 8px !important;
  color: #FA824C !important;
}

.orderNotes {
  word-break: break-word;
}

@media (max-width: 1199px) {
  .websocketDisconnect {
    width: 150%;
    margin-bottom: 32px !important;
  }
}
