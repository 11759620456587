.title {
  font-size: 96px;
  font-weight: bold;
}

.subtitle {
  font-size: 60px;
}

.websocketDisconnect {
  margin-bottom: 32px !important;
}

.doneOrders {
  display: flex;
}

.orderTypeCol {
  margin: 0px 16px;
  flex: 1;
}

.orderTypeColHeading {
  text-align: center;
  padding-top: 16px;
  font-size: 80px;
  font-weight: bold;
}

.doneOrder {
  padding: 16px;
  margin: 16px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.08);
  background-color: white;
  font-size: 50px;
}

.doneOrderTitle {
  font-weight: bold;
}

.empty {
  background-color: white;
  padding: 16px;
  margin: 16px;
}
