.pointsTableRow {
  background-color: inherit;
}

.pointsTableRowSelected {
  background-color: #BF7547;
  color: white;
}

.pointsTableRowSelected:hover > td {
  background-color: #BF7547 !important;
  color: white;
}
