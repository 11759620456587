.colCard {
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12);
}

.heading {
  font-size: 18px;
  font-weight: bold;
  overflow: auto;
}

.elems {
  max-height: 80vh;
  overflow: auto;
}

.elemCard {
  margin-bottom: 8px !important;
}

.elemInfo {
  flex: 1;
  padding-top: 16px;
  padding-bottom: 16px;
}

.cancel {
  height: inherit !important;
}

/*
 * Hack to reset modal wrapper width to screen size since
 * ControlStation pages are at 150% of screen width.
 */
.modalWrap {
  width: 60%;
}

.modalFooter {
  text-align: left;
}
