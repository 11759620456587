.toggle {
  float: right;
}

.star {
  font-size: 60px !important;
  margin: 8px !important;
  align-self: center;
}

.orderDetails {
  font-size: 16px;
}

.orderNotes {
  margin-top: 8px;
}

.itemNotes {
}

.item {
  border-bottom: 1px solid #f0f0f0;
  padding: 8px 0px !important;
}

.lastItem {
  border-bottom: none;
}

.movingCollapsedItem {
  font-size: 18px;
  border-bottom: 1px solid #f0f0f0;
  padding: 8px 0px !important;
}

.noActionItem {
  color: rgba(0, 0, 0, 0.25);
}

.drawerContent {
  height: 100%;
  overflow-y: auto;
}

.startButtonContainer {
  background: white;
  width: 100%;
}

.startButton {
  background-color: rgba(0, 79, 172, 0.5) !important;
  color: #fff !important;
  width: 96px;
  margin-left: 16px;
}
